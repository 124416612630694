<template>
  <v-container grid-list-xs v-if="!loading">
    <v-layout row wrap>
      <v-flex xs12 sm4>
        <h3>{{ $t('Common.complaint_report') }}</h3>
      </v-flex>

      <v-flex xs12 sm6 v-if="userNotFound">
        <v-alert type="warning" :value="true" outlined>
          Profile doesn't exists anymore.
        </v-alert>
      </v-flex>
      <v-flex v-else-if="userProfile" xs12 sm4>
        <profile-view :user="userProfile" />
      </v-flex>
    </v-layout>
    <template v-if="!id">
      <v-layout row wrap v-if="!id">
        <v-flex xs12 sm5>
          <v-text-field
            name="subject"
            v-model="subject"
            label="Subject"
            id="subject"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm2 offset-md1>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-model="issueDate"
                label="Issue Date"
                prepend-icon="event"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="issueDate"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm8>
          <v-textarea
            outlined
            label="Detail description"
            v-model="message"
            required
            name="message"
            rows="6"
          ></v-textarea>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12> <b>Feedback Contact method</b> </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs12 sm3>
          <v-text-field
            v-model="email"
            label="Email"
            type="email"
            placeholder="Your email (optional)"
          ></v-text-field>
        </v-flex>
        <v-flex xs12 sm3 offset-md1>
          <v-text-field
            v-model="phone"
            mask="phone"
            label="Phone"
            placeholder="Your phone (optional)"
          ></v-text-field>
        </v-flex>
      </v-layout>
    </template>
    <template v-else>
      <v-layout row wrap>
        <v-flex xs12 sm8>
          <v-alert type="info" outlined :value="true">
            <h5>
              {{ $t('Common.complaint_submitted') }}

              {{ id }}
            </h5>
            <p>{{ $t('Common.complaint_submitted2') }}</p>
          </v-alert>
        </v-flex>
      </v-layout>
    </template>
    <v-layout row wrap>
      <v-flex xs12 sm4 class="no-print">
        <v-btn
          color="success"
          :disabled="!message || !subject || userNotFound"
          @click="send"
          >Submit</v-btn
        >
        <v-btn icon @click="$router.push('/')">
          <v-icon>arrow_back</v-icon>
        </v-btn>
      </v-flex>
      <div class="no-print" v-if="id">
        <v-btn color="primary" @click.prevent="print" class="mx-0"
          >Print
          <v-icon right>print</v-icon>
        </v-btn>
      </div>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileView from 'fod-core/src/components/message/ProfileView.vue'
export default {
  components: {
    ProfileView
  },
  computed: {
    ...mapGetters({
      loading: 'loading',
      profile: 'profile',
      userProfile: 'userProfile'
    })
  },
  data() {
    return {
      message: null,
      menu: false,
      id: null,
      issueDate: new Date().toISOString().substr(0, 10),

      subject: null,
      to: null,
      toUser: null,
      userNotFound: false,
      email: null,
      phone: null,
      lookupError: null
    }
  },
  mounted() {
    //this.$store.dispatch('clearUsers')
    if (this.$route.params.id) {
      this.to = this.$route.params.id
      console.log('Viewing message of:', this.to)

      this.$store
        .dispatch('loadUserProfile', { uid: this.to })
        .then(u => {
          if (!u) {
            console.log('user is missing')
            this.userNotFound = true
          }
        })
        .catch(e => {
          console.warn('user not found', e, this.to)
          this.userNotFound = true
        })
    } else {
      this.isNew = true
      console.log('Sending new message')
    }
  },

  methods: {
    send() {
      if (this.message) {
        let msg = {
          issueDate: this.issueDate,
          subject: this.subject,
          message: this.message,
          email: this.email,
          phone: this.phone,
          to: this.to
        }
        console.log('Sending complaint:', msg)
        this.$store.dispatch('reportComplaint', msg).then(c => {
          console.log('Complaint id:', c)
          this.subject = null
          this.message = null
          this.id = c
        })
      }
    },
    print() {
      this.$nextTick(() => {
        window.print()
      })
    }
  }
}
</script>
