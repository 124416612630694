<template>
  <v-layout
    row
    @click="viewMessages"
    wrap
    align-center
    justify-space-around
    fill-height
  >
    <v-flex xs4 sm1 hidden-xs-only>
      <v-avatar slot="activator" size="36px">
        <img v-if="profilePic(user)" :src="profilePic(user)" alt="Avatar" />
      </v-avatar>
    </v-flex>
    <v-flex xs4 md5> <strong v-html="user.name"></strong> </v-flex>
    <!-- <v-flex xs1>
      <v-icon>more_vert</v-icon>
    </v-flex> -->
  </v-layout>
</template>

<script>

export default {
  props: {
    user: {
      type: Object
    }
  },
  computed: {
    liveStatus() {
      return this.user.cookingStatus === 'cooking' ? `Cooking` : null
    },
    liveStreaming() {
      return this.user.stream_status === 'live'
    },
    cookingStatus() {
      return this.user.cookingStatus === 'cooking' ? `Cooking` : null
    },
    isCooking() {
      return this.user.cookingStatus === 'cooking'
    }
  },

  methods: {
    viewMessages() {
      console.log('viewing')
      this.$store.dispatch('getThread', {recipient: this.user.id}).then(thread_id => {
        this.$router.push('/messages/' + thread_id)
      })
    }
  }
}
</script>
